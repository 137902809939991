





















































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import { isEqual, orderBy } from 'lodash';
import clone from 'clone';
import { API, Videos } from '@/types';
import EventSelect from '@/components/EventSelect.vue';
import Tooltip from '@/components/Admin/Tooltip.vue';
import VideoComponent from './CurrentPlaylist/Video.vue';
import { storeModule, storeNS } from '../store';
import { rootModule } from '@/store';
import { apiDELETE, apiModify } from '@/api';

@Component({
  components: {
    EventSelect,
    Draggable,
    VideoComponent,
    Tooltip,
  },
})
export default class extends Vue {
  @storeNS.State((s) => s.loading.videoPlaylists) readonly loading!: boolean;
  @storeNS.State readonly videoPlaylists!: API.Videos.Playlists.GET[];
  selectedPlaylist: number | null = null;
  playlist: Videos.Playlist = [];
  err: boolean | string = false;
  editingName = false;
  nameEditTxt = '';

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  get errBool(): boolean {
    return !!this.err;
  }
  set errBool(val: boolean) {
    this.err = val;
  }

  get eventPlaylists(): API.Videos.Playlists.GET[] {
    return orderBy(this.videoPlaylists, ['name'], ['asc']);
  }

  get serverPlaylist(): API.Videos.Playlists.GET | undefined {
    return this.videoPlaylists.find((p) => p.id === this.selectedPlaylist);
  }

  @Watch('selectedEvent')
  onSelectedEventChange(): void {
    // Reset the select playlist on event change.
    this.selectedPlaylist = null;
  }

  @Watch('selectedPlaylist')
  onSelectedPlaylistChange(): void {
    this.copyServerPlaylist();
  }

  @Watch('serverPlaylist')
  onServerPlaylistChange(
    newVal?: API.Videos.Playlists.GET,
    oldVal?: API.Videos.Playlists.GET,
  ): void {
    if (isEqual(this.playlist, oldVal?.value) && this.nameEditTxt === oldVal?.name) {
      this.copyServerPlaylist();
    }
  }

  editName(): void {
    this.editingName = true;
    this.nameEditTxt = this.serverPlaylist?.name || '';
  }

  // eslint-disable-next-line max-len
  updateVideoCommercialLength({ id, length }: { id: string, length: API.Commercials.Start.POST['length'] }): void {
    const video = clone(this.playlist.find((v) => v.id === id));
    if (video) {
      video.commercial = length;
      const index = this.playlist.findIndex((v) => v.id === id);
      if (index >= 0) Vue.set(this.playlist, index, video);
    }
  }

  deleteVideo(id: string): void {
    const index = this.playlist.findIndex((v) => v.id === id);
    if (index >= 0) {
      this.playlist.splice(index, 1);
    }
  }

  copyServerPlaylist(): void {
    this.editingName = false;
    this.playlist = clone(this.serverPlaylist?.value ?? []);
  }

  get seperator(): string {
    return 'thin solid rgba(255, 255, 255, 0.12)';
  }

  get isModified(): boolean {
    return !isEqual(this.playlist, this.serverPlaylist?.value ?? [])
    || (this.editingName && this.nameEditTxt !== this.serverPlaylist?.name);
  }

  get formattedErr(): string | boolean {
    return typeof this.err === 'boolean'
      ? this.err
      : `${this.err.slice(0, 1).toUpperCase()}${this.err.slice(1)}.`;
  }

  discard(): void {
    this.copyServerPlaylist();
  }

  async save(): Promise<void> {
    try {
      const body = {
        eventId: this.selectedEvent ?? undefined,
        name: this.nameEditTxt
          ?? this.serverPlaylist?.name
          ?? `Playlist ${this.eventPlaylists.length + 1}`,
        value: this.playlist,
      };
      const { data } = await apiModify('videoPlaylists', this.selectedPlaylist, body);
      this.err = false;
      this.editingName = false;
      this.selectedPlaylist = data.id;
      await storeModule.loadVideoPlaylistsAPIData();
    } catch (err) {
      const { data } = err.response;
      if (data && data.error && !Array.isArray(data.error)) {
        this.err = data.error;
      } else {
        this.err = 'Some server error occured';
      }
    }
  }

  async del(): Promise<void> {
    if (this.selectedPlaylist) {
      await apiDELETE('videoPlaylists', this.selectedPlaylist);
      await storeModule.loadVideoPlaylistsAPIData(); // Force a reload if an entry has been deleted.
    }
  }
}
