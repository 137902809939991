
































import { Vue, Component } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import { v4 as uuid } from 'uuid';
import EventSelect from '@/components/EventSelect.vue';
import { API } from '@/types';
import VideoComponent from './AvailableVideos/Video.vue';
import { rootModule } from '@/store';
import { storeNS } from '../store';
import clone from 'clone';

@Component({
  components: {
    EventSelect,
    Draggable,
    VideoComponent,
  },
})
export default class extends Vue {
  @storeNS.State readonly availableVideos!: API.Videos.Available.GET['value'];
  @storeNS.State((s) => s.loading.availableVideos) readonly loading!: boolean;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  get videosSorted(): API.Videos.Available.GET['value'] {
    return clone(this.availableVideos)
      .sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }));
  }

  clone(original: API.Videos.Available.GET['value'][0]): API.Videos.Playlists.GET['value'][0] {
    return {
      sum: original.sum,
      id: uuid(),
      commercial: 0,
    };
  }

  get seperator(): string {
    return 'thin solid rgba(255, 255, 255, 0.12)';
  }
}
