

















import { Vue, Component, Prop } from 'vue-property-decorator';
import { API } from '@/types';

@Component
export default class extends Vue {
  @Prop({ type: Object, required: true }) readonly video!: API.Videos.Available.GET['value'][0];
}
