




















import { Vue, Component } from 'vue-property-decorator';
import { API } from '@/types';
import { rootModule } from '@/store';
import { storeNS } from '../store';

@Component
export default class extends Vue {
  @storeNS.State readonly availableVideos!: API.Videos.Available.GET['value'];
  @storeNS.State readonly videoPlayerData!: API.Videos.VPlayerData.GET['value'] | null;
  @storeNS.State readonly videoPlaylists!: API.Videos.Playlists.GET[];

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  get playlist(): API.Videos.Playlists.GET | undefined {
    return this.videoPlaylists.find((p) => p.id === this.playerData.video?.playlistId);
  }

  get playerData(): API.Videos.VPlayerData.GET['value'] {
    return this.videoPlayerData || { playing: false, video: null };
  }

  get currentVideoName(): string {
    return this.availableVideos.find((v) => (
      v.sum === this.playerData.video?.sum))?.name || '?';
  }

  get currentVideoPlaylistPos(): number {
    return (this.playlist?.value || []).findIndex((v) => v.id === this.playerData.video?.id);
  }

  get currentVideoProgress(): string {
    return `${this.playerData.video?.position || '0'}s/${
      this.playerData.video?.duration || '?'}s`;
  }

  get seperator(): string {
    return 'thin solid rgba(255, 255, 255, 0.12)';
  }
}
