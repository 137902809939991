





































import { Vue, Component, Prop } from 'vue-property-decorator';
import { API } from '@/types';
import { storeNS } from '../../store';
import { rootModule } from '@/store';

@Component
export default class extends Vue {
  @Prop({ type: Object, required: true }) readonly video!: API.Videos.Playlists.GET['value'][0];
  @storeNS.State readonly availableVideos!: API.Videos.Available.GET['value'];
  commercialLengths = [
    { str: 'OFF', val: 0 },
    { str: '1m', val: 60 },
    { str: '2m', val: 120 },
    { str: '3m', val: 180 },
    { str: '4m', val: 240 },
    { str: '5m', val: 300 },
    { str: '6m', val: 360 },
  ];

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  created(): void {
    if (typeof this.video.commercial !== 'number') {
      this.changeCommercialLength(0);
    }
  }

  get name(): string {
    return this.availableVideos.find((v) => v.sum === this.video.sum)?.name || '?';
  }

  changeCommercialLength(val: number): void {
    this.$emit('updateCommercialLength', { id: this.video.id, length: val });
  }

  del(): void {
    this.$emit('delete', this.video.id);
  }

  commercialBtnColour(i: number): string {
    return i === this.video.commercial ? 'green darken-1' : 'red darken-4';
  }
}
