




















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class extends Vue {
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop({ type: String, default: 'left' }) readonly side!: 'left' | 'right';
}
