import { apiGETMulti, apiGETSingle } from '@/api';
import { rootModule, store } from '@/store';
import { API } from '@/types';
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

const moduleName = 'admin_videoplaylists';
if (store.hasModule(moduleName)) store.unregisterModule(moduleName);

@Module({ dynamic: true, store, namespaced: true, name: moduleName })
export class StoreModule extends VuexModule {
  availableVideos: API.Videos.Available.GET['value'] = [];
  videoPlayerData: API.Videos.VPlayerData.GET['value'] | null = null;
  videoPlaylists: API.Videos.Playlists.GET[] = [];
  loading = { availableVideos: false, videoPlayerData: false, videoPlaylists: false };

  @Mutation
  setLoading({ key, val }: { key: string, val: boolean }): void {
    Vue.set(this.loading, key, val);
  }

  @Mutation
  setProperty({ key, val }: { key: string, val: unknown }): void {
    Vue.set(this, key, val);
  }

  @Action
  async loadAvailableVideosAPIData(): Promise<void> {
    this.setLoading({ key: 'availableVideos', val: true });
    if (rootModule.selectedEvent) {
      try {
        const data = (await apiGETSingle('availableVideos', rootModule.selectedEvent)).data.value;
        this.setProperty({ key: 'availableVideos', val: data || [] });
      } catch (err) {
        this.setProperty({ key: 'availableVideos', val: [] });
      }
    }
    this.setLoading({ key: 'availableVideos', val: false });
  }

  @Action
  async loadVideoPlayerDataAPIData(): Promise<void> {
    this.setLoading({ key: 'videoPlayerData', val: true });
    if (rootModule.selectedEvent) {
      try {
        const data = (await apiGETSingle('videoPlayerData', rootModule.selectedEvent)).data.value;
        this.setProperty({ key: 'videoPlayerData', val: data });
      } catch (err) {
        this.setProperty({ key: 'videoPlayerData', val: null });
      }
    }
    this.setLoading({ key: 'videoPlayerData', val: false });
  }

  @Action
  async loadVideoPlaylistsAPIData(): Promise<void> {
    this.setLoading({ key: 'videoPlaylists', val: true });
    if (rootModule.selectedEvent) {
      try {
        const { data } = (await apiGETMulti('videoPlaylists', {
          eventId: rootModule.selectedEvent,
          offset: 0,
        }, true));
        this.setProperty({ key: 'videoPlaylists', val: data });
      } catch (err) {
        this.setProperty({ key: 'videoPlaylists', val: [] });
      }
    }
    this.setLoading({ key: 'videoPlaylists', val: false });
  }

  @Action
  // eslint-disable-next-line camelcase
  socket_availableVideosModified([val]: (API.Videos.Available.GET | null)[]): void {
    this.setProperty({ key: 'availableVideos', val: val?.value || [] });
  }

  @Action
  // eslint-disable-next-line camelcase
  socket_videoPlayerDataModified([val]: (API.Videos.VPlayerData.GET | null)[]): void {
    this.setProperty({ key: 'videoPlayerData', val: val?.value || null });
  }

  @Mutation
  SOCKET_VIDEOPLAYLISTMODIFIED([newVal, oldVal]: (API.Videos.Playlists.GET | null)[]): void {
    const index = this.videoPlaylists.findIndex((p) => p.id === oldVal?.id);
    if (newVal && !oldVal) this.videoPlaylists.push(newVal);
    else if (!newVal && oldVal && index >= 0) this.videoPlaylists.splice(index, 1);
    else if (index >= 0) {
      Vue.set(this.videoPlaylists, index, { ...this.videoPlaylists[index], ...newVal });
    }
  }
}

export const storeModule = getModule(StoreModule, store);
export const storeNS = namespace(moduleName);
