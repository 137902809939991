














import { Vue, Component } from 'vue-property-decorator';
import { API } from '@/types';
import { rootModule } from '@/store';
import { apiGETMulti } from '@/api';

@Component
export default class extends Vue {
  events: API.Events.GET[] = [];
  loading = false;

  get event(): number | null { return rootModule.selectedEvent; }
  set event(val: number | null) { rootModule.setSelectedEvent(val); }

  // TODO: Handle errors!
  async loadAPIData(): Promise<void> {
    this.loading = true;
    this.events = (await apiGETMulti('events', {}, true)).data;
    this.loading = false;
  }

  async created(): Promise<void> {
    await this.loadAPIData();
  }
}
