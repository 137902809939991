






























import { Vue, Component, Watch } from 'vue-property-decorator';
import EventSelect from '@/components/EventSelect.vue';
import CurrentPlaylist from './components/CurrentPlaylist.vue';
import AvailableVideos from './components/AvailableVideos.vue';
import CurrentPlaybackStatus from './components/CurrentPlaybackStatus.vue';
import { rootModule } from '@/store';
import { storeModule } from './store';

@Component({
  components: {
    EventSelect,
    CurrentPlaylist,
    AvailableVideos,
    CurrentPlaybackStatus,
  },
})
export default class extends Vue {
  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  @Watch('selectedEvent', { immediate: true })
  async onSelectedEventChange(): Promise<void> {
    await storeModule.loadAvailableVideosAPIData();
    await storeModule.loadVideoPlayerDataAPIData();
    await storeModule.loadVideoPlaylistsAPIData();
  }
}
